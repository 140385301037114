import {
  Box,
  Card,
  Stack,
  Tooltip,
  Typography,
  styled,
  useTheme,
} from "@mui/material"
import { Info } from "react-feather"

type StockCardProps = {
  title: string
  stockValue: string
  tooltip?: string
  dateLine?: string
}

export const StockCard = ({
  title,
  stockValue,
  tooltip,
  dateLine,
}: StockCardProps) => (
  <Card
    component={Stack}
    px={3}
    pt={1}
    sx={{ border: "none", position: "relative" }}
    justifyContent="center"
  >
    <StockBorder />

    <Box display="grid" gridTemplateRows="54px 1fr auto">
      <Stack direction="row">
        <StockTitle>{title}</StockTitle>
        {!!tooltip && <InfoTooltip title={tooltip} />}
      </Stack>
      <Box alignSelf="end">
        <StockValue>{stockValue}</StockValue>
        {dateLine && <StockDate>{`au ${dateLine}`}</StockDate>}
      </Box>
    </Box>
  </Card>
)

export const StockBorder = styled(Box)(({ theme }) => ({
  position: "absolute",
  display: "block",
  top: "1px",
  bottom: "-10px",
  left: "2px",
  width: "4px",
  height: "99%",
  backgroundColor: theme.palette.primary.dark1,
  borderRadius: "4px",
}))

const StockTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 600,
  fontSize: theme.typography.pxToRem(16),
  lineHeight: theme.typography.pxToRem(18),
  textTransform: "uppercase",
  marginBottom: theme.spacing(1),
}))

const StockValue = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark1,
  fontSize: theme.typography.pxToRem(23),
  lineHeight: theme.typography.pxToRem(24),
  fontWeight: 600,
}))

const StockDate = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark1,
  fontSize: theme.typography.pxToRem(12),
  paddingBottom: theme.spacing(0.7),
}))

export const InfoTooltip = ({ title }: { title: string }) => {
  const { typography } = useTheme()
  return (
    <Tooltip title={title} placement="top">
      <Info color="#c9d0d6" size={typography.pxToRem(27.5)} />
    </Tooltip>
  )
}
