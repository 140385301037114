import { Box, Skeleton, Stack, Typography, styled } from "@mui/material"
import {
  PaperContainer,
  type PaperContainerProps,
  formatNumber,
  formatPrice,
} from "@pharmupp/p3-front-commons"
import { type ReactNode, Suspense } from "react"

import { useMyLabOrdersApi } from "@pharmupp/p3-offers-domain"
import { usePurchasesSummaryApi } from "../../api/usePurchasesSummaryApi"
import { useSalesSummaryApi } from "../../api/useSalesSummaryApi"
import { useStocksSummaryApi } from "../../api/useStocksSummaryApi"
import { BoxIcon } from "../../icons/BoxIcon"
import { EuroBifton } from "../../icons/EuroBifton"
import { Inventory } from "../../icons/Inventory"
import { PriceTag } from "../../icons/PriceTag"
import { GreyLabel } from "./commons"

type SalesStatsBlock = PaperContainerProps & { labId: string }

export const SalesStatsBlock = ({ labId, ...props }: SalesStatsBlock) => (
  <Suspense fallback={<Loader {...props} />}>
    <SalesStatsBlockInner labId={labId} {...props} />
  </Suspense>
)

export const SalesStatsBlockInner = ({ labId, ...props }: SalesStatsBlock) => {
  const { count: ordersCount, isLoading: isLoadingOrders } = useMyLabOrdersApi({
    labId,
  })
  const { purchasesSummary, isLoading: isLoadingPurchases } = usePurchasesSummaryApi(
    { labId },
  )
  const { salesSummary, isLoading: isLoadingSales } = useSalesSummaryApi({ labId })
  const { stocksSummary, isLoading: isLoadingStocks } = useStocksSummaryApi({
    labId,
  })

  if (isLoadingOrders || isLoadingPurchases || isLoadingSales || isLoadingStocks) {
    return <Loader {...props} />
  }

  return (
    <PaperContainer {...props}>
      <Box
        display="grid"
        gridTemplateColumns="repeat(4, 1fr)"
        gap={1}
        width="100%"
        height="100%"
      >
        <SalesStat
          Icon={<EuroBifton />}
          title="Ventes"
          label="Chiffre affaires"
          kpi={formatPrice(salesSummary?.turnover?.mainStat) || "-"}
        />
        <SalesStat
          Icon={<PriceTag />}
          title="Achats"
          label="Montant total"
          kpi={purchasesSummary ? formatPrice(purchasesSummary.amount) : "-"}
        />
        <SalesStat
          Icon={<Inventory />}
          title="Stocks"
          label="Valeur"
          kpi={stocksSummary ? formatPrice(stocksSummary.amount) : "-"}
        />
        <SalesStat
          Icon={<BoxIcon />}
          title="Commandes"
          label="Nombre"
          kpi={ordersCount == null ? "-" : formatNumber(ordersCount)}
        />
      </Box>
    </PaperContainer>
  )
}

const SalesStat = ({
  Icon,
  title,
  label,
  kpi,
}: { Icon: ReactNode; title: string; label: string; kpi: ReactNode }) => (
  <Stack
    justifyContent="center"
    alignItems="center"
    gap={1}
    height="100%"
    sx={{
      "&:not(:last-child)": {
        borderRight: "1px solid #eaedef",
      },
    }}
  >
    {Icon}
    <SalesStatBlockTitle>{title}</SalesStatBlockTitle>
    <Stack gap={0.5} alignItems="center">
      <GreyLabel>{label}</GreyLabel>
      <SalesStatKPI>{kpi}</SalesStatKPI>
    </Stack>
  </Stack>
)
const SalesStatBlockTitle = styled(Typography)(
  ({ theme: { palette, typography } }) => ({
    fontSize: typography.pxToRem(19),
    fontWeight: 500,
    lineHeight: typography.pxToRem(21),
    color: palette.common.darkBlue,
    textTransform: "uppercase",
  }),
)
const SalesStatKPI = styled(Typography)(({ theme: { palette, typography } }) => ({
  fontSize: typography.pxToRem(19),
  fontWeight: 600,
  lineHeight: typography.pxToRem(21),
  color: palette.common.darkBlue,
}))

const Loader = (props: PaperContainerProps) => (
  <Skeleton
    {...props}
    component={Box}
    variant="rectangular"
    width="100%"
    height="100%"
    sx={{ minHeight: "150px" }}
  />
)
