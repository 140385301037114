import { Yup } from "@pharmupp/p3-front-commons"
import { isValid } from "date-fns"

const today = new Date()
const getMinDateMessage = (fieldLabel: string, minDate: Date) =>
  `${fieldLabel} doit être supérieure au ${minDate.toLocaleDateString("fr-FR")}`

export const generalInfosSchema = Yup.object({
  isUpdate: Yup.boolean().nullable(),
  lastName: Yup.string().required().max(50),
  firstName: Yup.string().required().max(50),
  phone: Yup.string()
    .nullable()
    .when("type", {
      is: "HOLDER",
      then: (schema) => schema.required(),
    }),
  email: Yup.string().email().max(75).required(),
  type: Yup.string().required(),
  function: Yup.string().max(50),
  isExternSpecificationVisible: Yup.boolean(),
  externSpecification: Yup.string()
    .nullable()
    .when("isExternSpecificationVisible", {
      is: true,
      then: (schema) => schema.required(),
    }),
  programmedUnactivate: Yup.boolean().nullable(),
  unactivateDate: Yup.date()
    .nullable()
    .transform((currentValue) => (isValid(currentValue) ? currentValue : null))
    .when("programmedUnactivate", {
      is: true,
      then: (schema) =>
        schema
          .min(today, getMinDateMessage("La date de désactivation", today))
          .required("Veuillez saisir une date de désactivation"),
    }),
})
