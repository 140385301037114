import { Box, Link, Skeleton, Stack, Typography, styled } from "@mui/material"
import { Doughnut, formatPrice } from "@pharmupp/p3-front-commons"
import { Suspense, useState } from "react"
import { ExternalLink } from "react-feather"
import { type Period, useLabMarketShareApi } from "../../api/useLabMarketShareApi"
import { useSalesSummaryApi } from "../../api/useSalesSummaryApi"
import { PeriodSelector, type PeriodSelectorOption } from "../../commons"
import {
  KpiCardsStack,
  MainKpiCard,
  SecondaryKpiCard,
  SectionContentTitle,
  SectionContentTitleSkeleton,
  SectionLayout,
} from "./commons"

export const Sales = ({ labId }: { labId: string }) => {
  const [period, setPeriod] = useState<Period>("CURRENT_YEAR")
  const { salesSummary } = useSalesSummaryApi({ labId })

  return (
    <SectionLayout
      rightTitle={
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {/* TITLE + PERIOD PICKER */}
          <SectionContentTitle>Parts de marché catégorielles</SectionContentTitle>
          <PeriodSelector
            value={period}
            onChange={setPeriod}
            options={periodOptions}
          />
        </Box>
      }
    >
      {/* SIDE CARDS */}
      <KpiCardsStack>
        <MainKpiCard
          title="VENTES"
          subTitle="Chiffre affaires"
          kpi={formatPrice(salesSummary.turnover?.mainStat) || "-"}
          evolution={salesSummary.turnover?.evolution}
        />
        <SecondaryKpiCard
          title="Unités vendues"
          layout="evolution"
          kpi={salesSummary.units?.mainStat ?? "-"}
          evolution={salesSummary.units?.evolution}
        />
        <SecondaryKpiCard
          title="Nombre de références"
          kpi={salesSummary.numReferences ?? "-"}
          layout="evolution"
        />
      </KpiCardsStack>

      {/* DOUGHNUTS */}
      <Stack>
        <Suspense
          fallback={
            <GridLayout>
              {Array.from({ length: 3 }, (_, index) => (
                <GridItem key={index}>
                  <SectionContentTitleSkeleton width="60%" variant="rectangular" />
                  <Skeleton
                    variant="circular"
                    height={CHART_HEIGHT}
                    width={CHART_HEIGHT}
                  />
                </GridItem>
              ))}
            </GridLayout>
          }
        >
          <GridLayout>
            <Charts labId={labId} period={period} />
          </GridLayout>
        </Suspense>
      </Stack>
    </SectionLayout>
  )
}

const Charts = ({ labId, period }: { labId: string; period: Period }) => {
  const { labMarketShare } = useLabMarketShareApi({ labId, period })
  return (
    <>
      {labMarketShare.categories.length ? (
        labMarketShare.categories.map(
          ({ label, roundedTotalSales, laboratorySplits }) => (
            <GridItem key={label}>
              <SectionContentTitle mb={1}>{label}</SectionContentTitle>

              <Box height={CHART_HEIGHT} width="100%">
                <Doughnut data={laboratorySplits}>
                  <Typography variant="21px" fontWeight={600} color="primary.main">
                    {formatPrice(roundedTotalSales)}
                  </Typography>
                </Doughnut>
              </Box>

              {false && (
                <Link href="TODO:" underline="hover" display="inline-block" mt={1}>
                  <Stack direction="row" gap={1} m="0 auto" color="primary.dark4">
                    <Typography variant="14px">Top marques</Typography>
                    <ExternalLink size="14px" />
                  </Stack>
                </Link>
              )}
            </GridItem>
          ),
        )
      ) : (
        <Box height={CHART_HEIGHT} width="100%">
          <Typography>Aucune donnée</Typography>
        </Box>
      )}
    </>
  )
}

const COLUMNS_NBR = 3
const CHART_HEIGHT = "230px"
const GridLayout = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: `repeat(${COLUMNS_NBR}, 1fr)`,
  justifyContent: "center",
  justifyItems: "center",
  rowGap: theme.spacing(2),
  width: "100%",
}))

const GridItem = styled(Box)(({ theme }) => ({
  /** Center the 4th element if it's the last, otherwise does nothing */
  [`&:nth-of-type(${COLUMNS_NBR + 1}):nth-last-of-type(1)`]: {
    gridColumn: 2,
  },
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}))

const periodOptions: PeriodSelectorOption<Period>[] = [
  { value: "CURRENT_YEAR", label: new Date().getFullYear() },
  { value: "LAST_4_MONTHS", label: "4 mois échus" },
  { value: "LAST_12_MONTHS", label: "12 mois échus" },
]
