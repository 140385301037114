import { buildTheme } from "../const"

export const laboratoryTheme = buildTheme(({ palette }) => ({
  palette: {
    header: {
      containedButton: {
        backgroundColor: palette.common.tealBlue,
        primaryColor: palette.common.blueGreen2,
        secondaryColor: palette.common.white,
        hover: {
          backgroundColor: palette.common.midnightGreen,
        },
      },
      gradient: `linear-gradient(-180deg,${palette.common.tealBlueDark1} 0%,${palette.common.tealBlueDark2} 100%)`,
    },
    background: {
      default: "#f7f7f7",
    },
  },
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          "&.header-menu .MuiTab-root:hover": {
            color: palette.common.blueGreen2,
          },
          "&.header-menu .MuiTab-root.Mui-selected": {
            color: palette.common.blueGreen2,
          },
        },
      },
    },
  },
}))
