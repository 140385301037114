import type { ChipProps } from "@mui/material"
import { OperationType } from "../../operationMarketing.model"

export const MarketOPTypes: Record<
  OperationType,
  { label: string; shortLabel: string; color: ChipProps["color"] }
> = {
  [OperationType.BUSINESS]: {
    label: "Opérations métier",
    shortLabel: "Opé métier",
    color: "primary",
  },
  [OperationType.PROMOTIONAL]: {
    label: "Offres du moment",
    shortLabel: "Offre du moment",
    color: "success",
  },
  [OperationType.DISPLAY_BASIN]: {
    label: "Autres opérations",
    shortLabel: "Autre opé",
    color: "success",
  },
}

export type OperationState = OperationStateEnum
export enum OperationStateEnum {
  REGISTRABLE = "REGISTRABLE", // possible de s'inscrire
  UPCOMING = "UPCOMING", // pas possible de s'inscrire, date d'inscription dans le futur
  WAITING = "WAITING", // inscrit mais pas possible d'envoyer des justifs, date d'envoi des justifs dans le futur
  OUTDATED = "OUTDATED", // pas inscrit pendant la bonne période et date de fin d'inscription dans le passé
  EXPIRED = "EXPIRED", // inscrit, date de fin d'envoi de justifs est dans le passé, aucune justif envoyée ou validée
  STARTED = "STARTED", // inscrit mais aucun justif envoyé
  REVIEWED = "REVIEWED", // inscrit, justif envoyé (même si la date de fin d'envoi de justifs est dans le passé)
  REJECTED = "REJECTED", // inscrit, justif refusé, possible de renvoyer des justifs
  VALIDATED = "VALIDATED", // inscrit, justif validé (même si la date de fin d'envoi de justifs est dans le passé)
}

export const getJustifyOpLink = (operationId: string | number) =>
  `${window.CONTEXT.operationUrl}/login/${operationId}`

export enum OperationSection {
  ALL = "ALL",
  IN_PROGRESS = "IN_PROGRESS",
  REGISTERED = "REGISTERED",
  REGISTRABLE = "REGISTRABLE",
  VALIDATED = "VALIDATED",
  REQUIRES_PROOF = "REQUIRES_PROOF",
  OUTDATED = "OUTDATED",
}

export const MAX_PIXELS_AMOUNT_JUSTIFICATION_PIC = 1920 * 1080
